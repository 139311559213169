<template>
    <div class="content">
      <el-row class="bgW">
        <el-col :span="22">
          <!--检索条件-->
          <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
       
          </el-form>
        </el-col>
        <el-col :span="2" style="text-align: right;">
          <!-- <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button> -->
        </el-col>
      </el-row>
      <div class="accountCon">
        <!-- <div class="btnBox">
          <el-button
            v-if="!userInfo.shop_id"
            type="primary"
            :disabled="!idList.length"
            class="mb10"
            size="mini"
            @click="hint('导出选中列表')"
          >导出数据</el-button>
        </div> -->
        <!--列表-->
        <el-row>
          <el-col :span="24">
            <el-table
              :data="list"
              size="small"
              border
              :cell-style="$style.cellStyle"
              :header-cell-style="$style.rowClass"
              @selection-change="handleSelectionChange"
            >
              <ElTableColumn v-if="!userInfo.shop_id" type="selection" width="50" />
              <ElTableColumn label="ID"  prop="id" />
              <ElTableColumn label="客户名称"  prop="customer_name" />
              <ElTableColumn label="出货日期" prop="create_time" />
              <ElTableColumn label="产品名称" prop="goods_name" />
              <ElTableColumn label="产品编码" prop="goods_code" />
              <ElTableColumn label="规格" prop="spec_name" />
              <ElTableColumn label="单价" prop="goods_price" />
              <ElTableColumn label="类型" prop="type" />
              <ElTableColumn label="出/退货单价" prop="price" />
              <ElTableColumn label="出/退货数量" prop="num" />
              <ElTableColumn label="出/退货金额" prop="amount_sum" />
              <ElTableColumn label="出/退货人" prop="operator_name" />
              <ElTableColumn label="出/退货门店" prop="shop_name" />
            </el-table>
            <pagination
              class="page tc mt10"
              :total="page.total"
              :page.sync="page.page"
              :limit.sync="page.limit"
              @pagination="getList"
            />
          </el-col>
        </el-row>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import pagination from "@/components/Pagination";
  import {
    clientExport,
    prestockGoodslistDetail
  } from "@/api/client";
  import { getShopList } from "@/api/shop";
  class Search {
    start_time = "";
    end_time = "";
    customer_name = ""; // 客户名称
    source_id = "";
    shop_id = "";
  }
  class Options {
    membership_leve = [];
    shop_id = [];
    source_id = [];
    scar_id = [];
  }
  class Page {
    total = 0;
    page = 1;
    limit = 10;
  }
  export default {
    name: "ClientList",
    components: {
      pagination
    },
    data() {
      return {
        search: new Search(), // 检索条件
        options: new Options(), // 选项
        page: new Page(),
        list: [],
        idList: ""
      };
    },
    computed: {
      ...mapGetters(["userInfo"])
    },
    mounted() {
      if (typeof this.$route.query.search == "object") {
        this.search = this.$route.query.search;
      }
      this.search.start_time = this.$route.query.start_time;
      this.search.end_time = this.$route.query.end_time;
      this.getList();
    },
    methods: {
      // 获取列表
      getList(option) {
        if (option === "search") this.page = new Page();
        prestockGoodslistDetail({ ...this.search, ...this.page, id: this.$route.query.id }).then(
          res => {
            this.list = res.data.list;
            this.page.total = res.data.dataCount;
          }
        );
      },
      // 跳转按钮
      tapBtn(routerName, id) {
        const query = {
          id: id,
          activeName: "first",
          search: this.search,
          pageName: "进店列表"
        };
        //this.$router.push({ name: routerName, query });
        let route = this.$router.resolve({ name: routerName, query });
        window.open(route.href, '_blank');
      },
      // 提示
      hint(text) {
        this.$confirm(`${text},是否确定？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.getExport();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消"
            });
          });
      },
      // 导出
      getExport() {
        clientExport({ id: this.idList, type: 1 }).then(res => {
          window.open(res.data.url);
        });
      },
      // 改变表格选中状态
      handleSelectionChange(val) {
        this.idList = val.map(i => {
          return i.id;
        });
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .mainColor {
    color: #2dc0a3;
  }
  .content {
    height: calc(100% - 56px);
    overflow-y: auto;
  }
  .bgW {
    background: #fff;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 20px;
  }
  
  .el-form-item {
    margin: 5px 20px 3px 0;
  }
  .accountCon {
    background: #fff;
    padding: 0px 10px 80px;
    .btnBox {
      display: flex;
      justify-content: space-between;
    }
  }
  </style>
  